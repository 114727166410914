<template>
  <section class="wrap">
    <dl class="Nbanner">
      <dt>一站式解决互联网问题</dt>
      <dd>大牛团队+技术支撑+营销方案</dd>
      <dd class="NBbtn"><a href="javascript:;">开启互联网新生活</a></dd>
    </dl>
  </section>
  <section class="wrap">
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">公司简介<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <dl class="Nabout">
      <dd><img src="../assets/images/about.jpg" alt=""></dd>
      <dt>
        <div class="NAmenu">了解我们</div>
        <div class="NAinfo">湖北汇充科技有限公司（简称：汇充科技）创立在湖北省武汉市光谷高新技术区。企业拥有年轻且成熟的技术团队，多年来不断创新、学习、突破、进取，深入互联网产品技术开发，透析各行业者切实需求，利用“软件+技术+服务”优势为客户提供稳定、可靠、可持续发展的一站式解决方案。同时，团队多年来致力于研磨打造生活服务平台，以技术驱动发展，让科技服务生活，用服务实现价值。</div>
      </dt>
    </dl>
  </section>
  <section class="bg_history minWrap">
    <article class="wrap">
      <div class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">发展历程<img src="../assets/images/Rmenu.png" class="menuIcon"></div>
    </article>
  </section>
  <section class="wrap">
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">企业文化<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <ul class="culture white">
      <li><a href="javascript:;">
        <img src="../assets/images/culture1.png" alt="">
        <div>
          <h6>专注</h6>
          <p>专注互联网前沿<br>技术的研究</p>
        </div>
      </a></li>
      <li><a href="javascript:;">
        <img src="../assets/images/culture2.png" alt="">
        <div>
          <h6>激情</h6>
          <p>以饱满的热情迎接<br>新的挑战</p>
        </div>
      </a></li>
      <li><a href="javascript:;">
        <img src="../assets/images/culture3.png" alt="">
        <div>
          <h6>使命</h6>
          <p>做一名产业互联网<br>的践行者</p>
        </div>
      </a></li>
      <li><a href="javascript:;">
        <img src="../assets/images/culture4.png" alt="">
        <div>
          <h6>诚信</h6>
          <p>诚信对待每一个<br>任务和用户</p>
        </div>
      </a></li>
      <li><a href="javascript:;">
        <img src="../assets/images/culture5.png" alt="">
        <div>
          <h6>共赢</h6>
          <p>互利共赢<br>共创未来</p>
        </div>
      </a></li>
    </ul>
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">办公环境<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <ul class="environment">
      <li><a href="javascript:;"><img src="../assets/images/environment1.png" alt=""></a></li>
      <li><a href="javascript:;"><img src="../assets/images/environment2.png" alt=""></a></li>
      <li><a href="javascript:;"><img src="../assets/images/environment3.png" alt=""></a></li>
      <li><a href="javascript:;"><img src="../assets/images/environment1.png" alt=""></a></li>
      <li><a href="javascript:;"><img src="../assets/images/environment2.png" alt=""></a></li>
      <li><a href="javascript:;"><img src="../assets/images/environment3.png" alt=""></a></li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    this.$emit('getBgClass', "companyBg");
  }
}
</script>
